var $gender = $("#gender");
var $bed_client_inputs = $(".client-beds").find(".use-typeahead");

async function setup_client_search() {
    if ($('#search_bar').length === 0) {
        return;
    }

    let source, client_data = [], has_loaded = false, has_errored = false;

    try {
        source = await fetch(site_url("clients/json"));
        client_data = await source.json();
        has_loaded = true;
    } catch (e) {
        // An error occurred. We can ignore it.
        has_errored = true;
    }

    let cached_queries = {};
    let cached_query_ids = {};
    let search = function (query) {
        if (typeof cached_queries[query] === "undefined") {
            cached_queries[query] = fuse.search(query).map(function (item) {
                return item.item;
            }).sort(function (a, b) {
                if (a.search_weight > b.search_weight) {
                    return -1;
                } else if (a.search_weight < b.search_weight) {
                    return 1;
                } else {
                    return 0;
                }
            }).slice(0, 6);

            cached_query_ids[query] = cached_queries[query].map(function (item) {
                return item.id;
            });
        }

        return cached_queries[query];
    }
    const fuse_options = {
        keys: ["tokens"],
        useExtendedSearch: true,
        ignoreLocation: true,
        includeScore: true,
        threshold: 0.1,
    };
    const fuse = new Fuse(client_data, fuse_options);
    let client_data_source = function (query, sync) {
        return sync(search(query));
    };

    let autoCompletejs = new autoComplete({
        data: {
            src: client_data
        },
        sort: function (a, b) {
            if (a.match.search_weight > b.match.search_weight) {
                return -1;
            } else if (a.match.search_weight < b.match.search_weight) {
                return 1;
            } else {
                return 0;
            }
        },
        query: {
            manipulate: function (query) {
                document.querySelectorAll('.no_result').forEach(e => e.remove());

                return query.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            },
        },
        placeHolder: "search for a client",
        selector: "#search_bar",
        debounce: 6,
        searchEngine: function (query, record) {
            search(query);
            return cached_query_ids[query].indexOf(record.id) !== -1 ? record : false;
        },
        highlight: true,
        maxResults: 6,
        resultsList: {
            render: true,
            container: function (source) {
                source.setAttribute("id", "search_input_list");
            },
            element: "div",
            destination: document.querySelector(".search-results"),
            position: "afterend",
        },
        resultItem: {
            content: function (data, source) {
                source.setAttribute("class", "well search-result");
                source.innerHTML =
                    '            <div class="row">\n' +
                    '                <a href="#">\n' +
                    '                    <div class="col-md-4 col-lg-5">\n' +
                    '                        <img class="img-responsive client-picture" style="width:150px;" src="' + data.value.profile_picture + '">\n' +
                    '                    </div>\n' +
                    '                    <div class="col-md-8 col-lg-7 title">\n' +
                    '                        <h3>' + data.value.full_name + '</h3>\n' +
                    '                        <p>' + data.value.age + ' year old ' + data.value.gender + '</p>\n' +
                    '                        <p>DOB: ' + data.value.dob + '</p>\n' +
                    '                        <p>NI: ' + data.value.ni + '</p>\n' +
                    '                    </div>\n' +
                    '                </a>\n' +
                    '        </div>'
            },
            element: "div",
        },
        noResults: function () {
            let empty_message;

            if (has_errored) {
                empty_message = "Error loading client list, please refresh.";
            } else {
                empty_message = "No Result";
            }

            document.querySelectorAll('.no_result').forEach(e => e.remove());
            let result = document.createElement("p");
            result.setAttribute("class", "no_result");
            result.setAttribute("tabindex", "1");
            result.innerHTML = "<div class='tt-empty-message'>" + empty_message + "</div><a class='tt-footer' href='" + site_url('clients/create') + " '>Create New Client</a>";
            document.querySelector(".search_input").appendChild(result);
        },
        onSelection: function (feedback, placeholder) {
            $("body").addClass("loading");
            $('#search_overlay').hide();
            window.location.href = site_url("clients/view/" + feedback.selection.value.id);
        },
    });


    let options = {
        hint: true,
        highlight: true,
        minLength: 1
    };

    let templates_click = {
        name: 'clients',
        source: client_data_source,
        templates: {
            empty: [
                '<div class="tt-empty-message">',
                'No clients match your search.',
                '</div>'
            ].join(''),
            footer: [
                '<a class="tt-footer" href="' + site_url("clients/create") + '">Create New Client</a>'
            ].join(''),
            suggestion: Handlebars.compile([
                '<a href="' + site_url("clients/view/{{id}}") + '"><div class="row"><div class="col-xs-3"><img class="client-picture" src="{{profile_picture}}"></div>',
                '<div class="col-xs-9"><p class="client-name">{{full_name}}</p>',
                '<p class="client-description">{{age}} year old {{gender}}</p>',
                '</div></div></a>'
            ].join(''))
        }
    };

    let templates_autofill = {
        name: 'clients',
        source: client_data_source,
        displayKey: "full_name",
        templates: {
            empty: [
                '<div class="tt-empty-message">',
                'No clients match your search.',
                '</div>'
            ].join(''),
            footer: [
                '<a class="tt-footer" href="' + site_url("clients/create") + '">Create New Client</a>'
            ].join(''),
            suggestion: Handlebars.compile([
                '<a href="#" class="tt-suggestion" onclick="return false;"><div class="row"><div class="col-xs-3"><img class="client-picture" src="{{profile_picture}}"></div>',
                '<div class="col-xs-9"><p class="client-name">{{full_name}}</p>',
                '<p class="client-description">{{age}} year old {{gender}}</p>',
                '</div></div></a>'
            ].join(''))
        }
    };

    let initialise_typeahead = function (selector) {
        "use strict";

        var $selector = $(selector),
            $clicks = $selector.filter(".use-click-typeahead"),
            $autofills = $selector.not(".use-click-typeahead"),
            value = $gender.val();

        if ($selector.length > 0) {
            $clicks.removeClass('has-typeahead').typeahead('destroy');
            $clicks.addClass('has-typeahead').typeahead(options, templates_click);

            $autofills.removeClass('has-typeahead').typeahead('destroy');
            $autofills.addClass('has-typeahead').typeahead(options, templates_autofill);
        }
    }

    initialise_typeahead('.use-typeahead');

    window['initialise_typeahead'] = initialise_typeahead;
}

if (logged_in()) {
    setup_client_search();

    $('.mainnav-search-button').on('click', function () {
        $('#search_overlay').show();
        $("#search_bar").focus();
    });
    $('.mainnav-search-button-close').on('click', function () {
        $('#search_overlay').hide();
    });
    $('#search_overlay').click(function (event) {
        $('html').one('click', function () {
            $('#search_overlay').hide();
        });

        event.stopPropagation();
    });

    $('#search_input').click(function () {
        $(window).unbind('beforeunload');
    });

    $(document).on('typeahead:selected typeahead:autocompleted', '.use-typeahead:not(.use-click-typeahead)', function (event, suggestion) {
        "use strict";

        var $input = $(this).parents('.js-client-typeahead-container').find(".js-client-id");

        if (suggestion) {
            console.log(suggestion, $input);
            $input.val(suggestion.id);
        }
    });
}

(function () {
    "use strict";
    let postcode_selector = '#postcode:visible, [name="client[postcode]"]:visible';
    let local_authority_input_selector = '#ltla, [name="client[ltla]"]';
    const fuse_options = {
        keys: ["label"],
    };
    let fuse;
    let options;

    let lookup_local_authority = function () {
        const postcode = $(postcode_selector).val();

        fetch('https://api.postcodes.io/postcodes/' + postcode)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                let results = fuse.search(data.result.admin_district);
                let result;
                if (results.length) {
                    result = results[0].item.value;
                    $(local_authority_input_selector).val(result).trigger('change');
                }
            })
            .catch(function (error) {
                // Don't do anything, we can ignore errors.
            });
    };

    $(local_authority_input_selector).on('change', async function () {
        const data = await fetch(site_url('local_authority/' + $(this).val()));
        const json = await data.json();
        $('[name="utla"]:visible, [name="client[utla]"]:visible').val(json.utla);
    });

    if ($('.lookup-local-authority').length) {
        options = $(local_authority_input_selector).find('option').map(function (i, option) {
            return {label: option.text, value: option.value}
        }).toArray();
        fuse = new Fuse(options, fuse_options);

        $(document).on('input change', postcode_selector, function () {
            lookup_local_authority();
        });

        $(document).on('click', '.lookup-local-authority', function (event) {
            event.preventDefault();
            lookup_local_authority();
        });
    }

}());

(function () {
    "use strict";

    var cache = {"::1": "", "127.0.0.1": ""}, get_country = function get_country($item, ip) {
        if (cache[ip] === undefined) {
            cache[ip] = true;
            $.ajax({
                type: 'GET',
                url: '//ip-api.com/json/' + ip + '',
                contentType: "application/json",
                dataType: 'jsonp',
                success: function (json) {
                    cache[ip] = json.country_name;
                    if (json.country !== "" && json.country_name != "United Kingdom") {
                        $('[data-ip="' + ip + '"]').html("<strong class='text-danger'>" + json.country + "</strong>");
                    } else {
                        $('[data-ip="' + ip + '"]').text(json.country);
                    }
                },
                error: function (e) {
                    $('[data-ip="' + ip + '"]').text("ERROR: " + e.message);
                }
            });
        } else if (cache[ip] !== true) {
            $('[data-ip="' + ip + '"]').text(cache[ip]);
        }
    };

    window.get_country = get_country;
}());

update_wellbeing_chart = function () {
    var $holder = $('#wellbeing-chart');
    var option = $('#trend_category option[value="' + $('#trend_category').val() + '"]').text();
    var new_data;

    if ($holder.length > 0) {
        $.each(data, function (i, value) {
            if (value.label == option) {
                new_data = [value];
            }
        });

        if ($holder.width() > 0) {
            $.plot($holder, new_data, chartOptions);
        }
    }
};

$(document).ready(function () {
    if (window.location.href.indexOf("team_book") > -1) {

        var update_team_book = function () {
            $("tr td:nth-child(3)").each(function () {
                var cont = $(this).text();
                $(this).empty();
                $(this).append('<textarea class="form-control noteschange" type="text">' + cont + '</textarea>');
            });
        };

        update_team_book();
        $("body").on("page_updated", update_team_book);

        $(document).on('change', '.noteschange', function () {
            var id = $(this).parent().next().find('.findjsid').attr('forjsid');
            var instance_date = $(this).parents("tr").find(".instance-date").data("date");
            var curthis = $(this);
            $.ajax({
                type: "POST",
                url: site_url("team_book/update_note"),
                data: {id: id, value: $(this).val(), date: instance_date},
                success: function (data) {
                    if ((data + '').indexOf("#exception_error {") !== -1 || (data + '').indexOf("Unknown Error") !== -1) {
                        $("#loading_modal").modal('hide');
                        alert("An unknown error occurred. Please refresh and try again.");
                    } else {
                        curthis.attr('style', 'border-color:green');
                    }
                },
            });

        })
    }

    if ($('#note_client_user').length > 0) {
        if ($('#note_client_user').val() == '0') {
            $('.client_id').hide();
            $('.user_name').show();
        } else {
            $('.user_name').hide();
            $('.client_id').show();
        }

        $(document).on('change', '#note_client_user', function () {
            if ($(this).val() == '0') {
                $('.client_id').hide();
                $('.user_name').show();
            } else {
                $('.user_name').hide();
                $('.client_id').show();
            }
        });
    }
});

if (logged_in()) {
    let client = new MeiliSearch({
        host: site_url("meilisearch"),
        apiKey: "",
    });

    const queryForDuplicates = async function (first_name, middle_name, last_name, allow_merge = false, known_duplicates = []) {
        const $duplicate_or_banned_container = $(".js-detect-duplicate-or-banned");
        const currentClientId = parseInt($duplicate_or_banned_container.data("client-id"));
        const $template = $("#template-person").html();

        console.info("Querying for duplicates.", first_name, middle_name, last_name, allow_merge, known_duplicates);

        first_name = first_name ?? "";
        middle_name = middle_name ?? "";
        last_name = last_name ?? "";

        let query = "";

        if (first_name.length >= 1) {
            query += first_name;
        }

        if (middle_name.length >= 1) {
            query += " " + middle_name;
        }

        if (last_name.length >= 1) {
            query += " " + last_name;
        }

        if (first_name.length < 1 || last_name.length < 1) {
            $duplicate_or_banned_container.empty();
            return;
        }

        try {
            const result = await client.index(meilisearch_clients_index).search(query, {
                matchingStrategy: "all",
                filter: `id NOT IN ["${currentClientId}"]`,
            });

            const hits = result.hits.filter(hit => !known_duplicates.includes(hit.id));

            if (hits > 0) {
                $duplicate_or_banned_container.empty();

                let wording = `may be a duplicate`;
                let entered_wording = allow_merge ? `this client's name` : `the name you have entered`;
                let alertClass = 'alert-warning';
                let isBanned = false;
                const banned = hits.filter(hit => hit.is_banned);
                if (banned.length > 0) {
                    wording = `may be banned`;
                    alertClass = 'alert-danger';
                    isBanned = true;
                }

                const here_are_some_clients_that_match = hits.length === 1 ? "There is a client that matches" : "There are some clients that match";
                $duplicate_or_banned_container.append(`<div class="alert ${alertClass} flex items-center">
<i class="fa fa-2x fa-exclamation-triangle"></i>
<div class="ml-4">
<h4 class="alert-heading">This client ${wording}.</h4> 
<p>${here_are_some_clients_that_match} ${entered_wording}.
${isBanned ? "Please confirm before proceeding." : "If you are sure this is not a duplicate, you can ignore this."}
</p>
</div>
</div>
</div>`);
                let html = ['<div class="flex gap-2 flex-wrap justify-between">'];

                for (const hit of hits) {
                    if (allow_merge && false) {
                        hit.merge_url = site_url("clients/merge/" + hit.id + "/" + currentClientId);
                    }

                    if (allow_merge) {
                        hit.ignore_url = site_url("clients/ignore/" + hit.id + "/" + currentClientId);
                    }

                    if (hit.id !== currentClientId) {
                        hit.date_of_birth = hit.date_of_birth ? moment(hit.date_of_birth * 1000).format(momentjs_date_format) : "";
                        html.push(`<div class="flex-grow flex-shrink w-96">${Mustache.render($template, hit)}</div>`);
                    }
                }

                html.push('</div>');
                $duplicate_or_banned_container.append(html.join(''));
                $duplicate_or_banned_container.append('<hr />');
            } else {
                $duplicate_or_banned_container.empty();
            }
        } catch (e) {
            $duplicate_or_banned_container.empty();
        }
    }

    $(document).on('ready', function () {
        client = new MeiliSearch({
            host: site_url("meilisearch"),
            apiKey: meilisearch_tenant_key,
        });

        const $duplicate_or_banned_container = $(".js-detect-duplicate-or-banned");

        console.info("Setting up duplicate detection.", $duplicate_or_banned_container);

        if ($duplicate_or_banned_container.length > 0) {
            $(document).on('input', '[name="client[first_name]"], [name="client[middle_name]"], [name="client[last_name]"]', function () {
                const first_name = $('[name="client[first_name]"]').val();
                const middle_name = $('[name="client[middle_name]"]').val();
                const last_name = $('[name="client[last_name]"]').val();
                return queryForDuplicates(first_name, middle_name, last_name);
            });

            if ($duplicate_or_banned_container.is('[data-first-name]')) {
                const first_name = $duplicate_or_banned_container.data("first-name");
                const middle_name = $duplicate_or_banned_container.data("middle-name");
                const last_name = $duplicate_or_banned_container.data("last-name");
                const known_duplicates = $duplicate_or_banned_container.data("known-duplicates");
                return queryForDuplicates(first_name, middle_name, last_name, true, known_duplicates);
            }
        }
    });
}

$(".js-load-country").each(function () {
    "use strict";
    get_country($(this), $(this).data("ip"));
});

var $calendar = $('body #calendar');

if ($calendar.length > 0) {
    var options = {
        header: {
            left: 'prev,next today',
            center: 'title',
            right: $calendar.data("available-views") === undefined ? '' : $calendar.data("available-views")
        },
        defaultDate: (new Date()).toISOString(),
        defaultView: $calendar.data("default-view") === undefined ? 'basicWeek' : $calendar.data("default-view"),
        height: $calendar.data("height") === undefined ? 200 : $calendar.data("height"),
        editable: false,
        columnFormat: "ddd D/M",
        eventLimit: false,
        events: events,
        views: {
            agenda: {
                allDaySlot: false
            }
        }
    };

    if ($calendar.hasClass('staff-calendar')) {
        var $event_modal = $("#event-modal");
        var $event_modal_title = $event_modal.find(".modal-title");
        var $event_modal_body = $event_modal.find(".modal-body");

        options.eventClick = function (calEvent) {
            "use strict";

            if (calEvent.type === "item") {
                var $edit_modal = $("#edit-calendar-item");
                var $delete_modal = $("#delete-calendar-item");

                var $edit_modal_name = $edit_modal.find("#name");
                var $edit_modal_type_id = $edit_modal.find("#calendar_item_type_id");
                var $edit_modal_startdate_time = $edit_modal.find("#start_datetime");
                var $edit_modal_enddate_time = $edit_modal.find("#end_datetime");
                var $edit_modal_form = $edit_modal.find("#edit_cal_form");

                var $delete_modal_form = $delete_modal.find("#delete_cal_form");

                $edit_modal_name.val(calEvent.title);
                $edit_modal_type_id.val(calEvent.type_id);

                function process_date(datevalue) {

                    if (datevalue != null) {
                        var setdf = new Date(datevalue);
                        var day;
                        var month;
                        var hh;
                        var mm;

                        if (setdf.getDate() < 10) {
                            day = '0' + setdf.getDate();
                        } else {
                            day = setdf.getDate();
                        }

                        if ((setdf.getMonth() + 1) < 10) {
                            month = '0' + (setdf.getMonth() + 1);
                        } else {
                            month = (setdf.getMonth() + 1);

                        }
                        if ((setdf.getHours()) < 10) {
                            hh = '0' + (setdf.getHours() - 1);
                        } else {
                            hh = (setdf.getHours() - 1);

                        }
                        if ((setdf.getMinutes()) < 10) {
                            mm = '0' + (setdf.getMinutes());
                        } else {
                            mm = (setdf.getMinutes());

                        }
                        return (day + "/" + month + "/" + setdf.getFullYear() + " " + hh + ":" + mm);
                    } else {
                        return false;
                    }
                }

                var newDate = process_date(calEvent.start);
                var endReturn = process_date(calEvent.end);


                var form_url = $edit_modal_form.attr('action');
                var form_url_delete = $delete_modal_form.attr('action');


                $edit_modal_form.attr('action', form_url + "/" + (calEvent.id.replace("item_", "")));
                $delete_modal_form.attr('action', form_url_delete + "/" + (calEvent.id.replace("item_", "")));


                if (endReturn != false) {
                    $edit_modal_enddate_time.val(endReturn);
                }

                $edit_modal_startdate_time.val(newDate);


                $edit_modal.modal();
            } else if (calEvent.type !== "session") {
                return;
            } else {

                var message = "";

                if (calEvent.is_cancelled) {
                    message = "This session was cancelled by the Session Lead.";
                } else if (!calEvent.is_finished) {
                    message = "This session has not yet occurred.";
                } else if (calEvent.attended) {
                    message = "The client attended this session.";
                } else {
                    message = "The client did not attend this session.";
                }

                if (calEvent.notes !== "") {
                    message = "<p>" + message + "</p><br />" + "<h5>Session Notes</h5>" + calEvent.notes;
                }

                $event_modal_title.html(calEvent.title);
                $event_modal_body.html(message);
                $event_modal.modal('show');
            }
        };
    } else if ($calendar.is('body.clients #calendar_tab #calendar')) {
        var $event_modal = $("#event-modal");
        var $event_modal_title = $event_modal.find(".modal-title");
        var $event_modal_body = $event_modal.find(".modal-body");

        options.eventClick = function (calEvent) {
            "use strict";

            if (calEvent.type === "item") {
                var $edit_modal = $("#edit-calendar-item");
                var $delete_modal = $("#delete-calendar-item");

                var $edit_modal_name = $edit_modal.find("#name");
                var $edit_modal_type_id = $edit_modal.find("#calendar_item_type_id");
                var $edit_modal_startdate_time = $edit_modal.find("#start_datetime");
                var $edit_modal_enddate_time = $edit_modal.find("#end_datetime");
                var $edit_modal_form = $edit_modal.find("#edit_cal_form");

                var $delete_modal_form = $delete_modal.find("#delete_cal_form");

                $edit_modal_name.val(calEvent.title);
                $edit_modal_type_id.val(calEvent.type_id);

                function process_date(datevalue) {

                    if (datevalue != null) {
                        var setdf = new Date(datevalue);
                        var day;
                        var month;
                        var hh;
                        var mm;

                        if (setdf.getDate() < 10) {
                            day = '0' + setdf.getDate();
                        } else {
                            day = setdf.getDate();
                        }

                        if ((setdf.getMonth() + 1) < 10) {
                            month = '0' + (setdf.getMonth() + 1);
                        } else {
                            month = (setdf.getMonth() + 1);

                        }
                        if ((setdf.getHours()) < 10) {
                            hh = '0' + (setdf.getHours() - 1);
                        } else {
                            hh = (setdf.getHours() - 1);

                        }
                        if ((setdf.getMinutes()) < 10) {
                            mm = '0' + (setdf.getMinutes());
                        } else {
                            mm = (setdf.getMinutes());

                        }
                        return (day + "/" + month + "/" + setdf.getFullYear() + " " + hh + ":" + mm);
                    } else {
                        return false;
                    }
                }

                var newDate = process_date(calEvent.start);
                var endReturn = process_date(calEvent.end);


                var form_url = $edit_modal_form.attr('action');
                var form_url_delete = $delete_modal_form.attr('action');


                $edit_modal_form.attr('action', form_url + "/" + (calEvent.id.replace("item_", "")));
                $delete_modal_form.attr('action', form_url_delete + "/" + (calEvent.id.replace("item_", "")));


                if (endReturn != false) {
                    $edit_modal_enddate_time.val(endReturn);
                }

                $edit_modal_startdate_time.val(newDate);


                $edit_modal.modal();
            } else if (calEvent.type !== "session") {
                return;
            } else {

                var message = "";

                if (calEvent.is_cancelled) {
                    message = "This session was cancelled by the Session Lead.";
                } else if (!calEvent.is_finished) {
                    message = "This session has not yet occurred.";
                } else if (calEvent.attended) {
                    message = "The client attended this session.";
                } else {
                    message = "The client did not attend this session.";
                }

                if (calEvent.notes !== "") {
                    message = "<p>" + message + "</p><br />" + "<h5>Session Notes</h5>" + calEvent.notes;
                }

                $event_modal_title.html(calEvent.title);
                $event_modal_body.html(message);
                $event_modal.modal('show');
            }
        };
    }

    $calendar.fullCalendar(options);
}
$('#calendar_tab').removeClass('in active');

function submit_date_range() {
    var url = 'reports/' + report_slug + '/' + $('#user_id').val() + '/' + $('#from_yy').val() + '-' + $('#from_mm').val() + '-' + $('#from_dd').val() + '/' + $('#to_yy').val() + '-' + $('#to_mm').val() + '-' + $('#to_dd').val();
    var $client_id = $('.reports-filters #client_id');
    var $type_id = $('.reports-filters #type_id');

    if ($(this).is(".include-client-id")) {
        if ($client_id.length > 0) {
            url += '/' + $client_id.val();
        } else {
            url += "/all";
        }
    }

    if ($(this).is(".include-additional-treatment-type-id")) {
        if ($type_id.length > 0) {
            url += '/' + $type_id.val();
        } else {
            url += "/all";
        }
    }

    window.location = site_url(url);
}

function update_button() {

    if (typeof (report_tpl) === "undefined") {
        return;
    }

    var $client_id = $('.reports-filters #client_id');
    var $type_id = $('.reports-filters #type_id');
    var client_id, type_id;

    if ($client_id.length > 0) {
        client_id = $client_id.find('option:selected').text();
    }

    if ($type_id.length > 0) {
        type_id = $type_id.find('option:selected').text();
    }

    var from = $('#from_dd option:selected').text() + ' ' + $('#from_mm option:selected').text() + ' ' + $('#from_yy option:selected').text();
    var to = $('#to_dd option:selected').text() + ' ' + $('#to_mm option:selected').text() + ' ' + $('#to_dd option:selected').text();
    var user_id = $('#user_id option:selected').text();

    var buffer = report_tpl;
    buffer = buffer.replace("{from}", from).replace("{to}", to).replace("{user_id}", user_id).replace("{type_id}", type_id).replace("{client_id}", client_id)

    $('.view_audit_trail_button').html(buffer);
}

$('.js-gender-edit-dropdown .dropdown-menu a').on("click", function gene(event) {
    "use strict";
    event.preventDefault();

    var $dropdown = $(".js-gender-edit-dropdown"), $el = $(this), val = $el.data('value');

    $.get($el.attr("href")).always(function (data) {
        var $panel = $el.parents(".panel"), new_class = '';

        if (data === "OK") {
            switch (val) {
                case "M":
                    new_class = 'info';
                    break;
                case "F":
                    new_class = 'danger';
                    break;
                case "X":
                    new_class = 'warning';
                    break;
            }

            $dropdown.find(".photographer-name").html($el.html());
            $dropdown.find(".active").removeClass("active");
            $el.parent().addClass("active");
            $panel.removeClass().addClass("panel panel-" + new_class);
            $panel.find(".panel-heading .label").removeClass().addClass("label pull-right label-" + new_class);

        } else {
            alert("An unknown error occurred while trying to change the gender for this bed.");
        }
    });
});

$(".js-clickable-calendar").on("dp.change", function (event) {
    "use strict";
    var $el = $(this);

    if (event.oldDate && !event.oldDate.isSame(event.date, 'day')) {
        window.location.href = site_url($el.data('url').split('{value}').join(event.date.format("YYYY-MM-DD")));
    }
});

$(".js-daily-calendar-date").on("dp.change", function (event) {
    "use strict";

    if (event.oldDate && !event.oldDate.isSame(event.date, 'day')) {
        window.location.href = site_url("sessions/daily_calendar/" + event.date.format("YYYY-MM-DD"));
    }
});

$(".js-daily-calendar-site, .js-weekly-calendar-site").on("change input", function () {
    window.location.href = $(this).data("url") + $(this).val();
});

$(".js-weekly-calendar-date").on("dp.change", function (event) {
    "use strict";

    if (event.oldDate && !event.oldDate.isSame(event.date, 'day')) {
        window.location.href = site_url("sessions/index/" + event.date.format("YYYY-MM-DD"));
    }
});

$('.view_audit_trail_button').on('click', submit_date_range);
$('.date-ranges-select-container select, .reports-filters #user_id, .reports-filters #client_id, .reports-filters #type_id').on('change', update_button);

$gender.on("change", function () {
    "use strict";

    var value = $gender.val();

    $bed_client_inputs.each(function () {
        var gender = $(this).data("gender");
        if (gender !== value && value !== "X") {
            $(this).val("");
        }
    });

    initialise_typeahead('.use-typeahead');
});

update_button();

$behaviour_queues = $(".js-behaviour-queue");
$(".js-queue-id-selector").on("change", function () {
    "use strict";
    var value = $(this).val();
    var $queue;
    var $queue_code_container;
    var current_bed_unit_id;
    var current_bedroom_id;
    var current_expected_date_of_discharge;
    var current_date_of_admission;
    var current_date_of_expected_admission;
    var current_date_of_assessment;
    var current_date_of_expected_assessment;

    $behaviour_queues.not(".js-queue-" + value).hide();
    if (value != queue_id) {
        $queue_code_container = $(".queue-code-container");
        current_expected_date_of_discharge = $queue_code_container.data("current-expected-date-of-discharge");
        current_date_of_admission = $queue_code_container.data("current-date-of-admission");
        current_date_of_expected_admission = $queue_code_container.data("current-date-of-expected-admission");
        current_date_of_expected_assessment = $queue_code_container.data("current-date-of-expected-assessment");
        current_date_of_assessment = $queue_code_container.data("current-date-of-assessment");
        $queue = $(".js-queue-" + value);

        if (current_expected_date_of_discharge) {
            $queue.find(".js-expected-date-of-discharge").val(current_expected_date_of_discharge).change();
        }

        if (current_date_of_expected_admission) {
            $queue.find(".js-date-of-expected-admission").val(current_date_of_expected_admission).change();
        }

        if (current_date_of_expected_assessment) {
            $queue.find(".js-date-of-expected-assessment").val(current_date_of_expected_assessment).change();
        }

        if (current_date_of_assessment) {
            $queue.find(".js-date-of-assessment").val(current_date_of_assessment).change();
        }

        if (current_date_of_admission) {
            $queue.find(".js-date-of-admission").val(current_date_of_admission).change();
        }

        refresh_discharge_reasons($queue.find('.js-discharge-reason'), value);

        $queue.show();

        // Refresh again to take into account custom category selection.
        refresh_discharge_reasons($queue.find('.js-discharge-reason'), value);
    }
});

$(document).on('change', '[name$="[category]"]', function () {
    let $discharge_reasons = $(this).parents('.js-behaviour-queue').find('.js-discharge-reason');
    let queue_id = $('[name="queue_id"]').val();
    refresh_discharge_reasons($discharge_reasons, queue_id);
});

$(document).on('change', '.js-discharge-reason', function () {
    refresh_custom_discharge_reason($(this).val());
});

function refresh_custom_discharge_reason(current_reason_label) {
    if (current_reason_label.indexOf("Other") !== -1) {
        $(".js-is-other-discharge-reason").hide().removeClass("hidden").show();
    } else {
        $(".js-is-other-discharge-reason").hide();
    }
}

function refresh_discharge_reasons($discharge_reasons, queue_id) {
    let $category = $('[name$="[category]"]:visible:checked');
    let category = $category.val();
    let options = [{
        key: "",
        label: "-- Select --",
        html: '<option value="">-- Select --</option>'
    }];

    if (typeof discharge_reasons_per_queue[queue_id] !== "undefined") {
        Object.keys(discharge_reasons_per_queue[queue_id]).map(function (key) {
            let label = discharge_reasons_per_queue[queue_id][key];

            if ($category.length) {
                if (label.indexOf(`[${category}]`) === -1) {
                    return;
                }

                label = label.replace("[Planned]", "").replace("[Unplanned]", "").replace("[BDA]", "").replace("[BFS]", "").replace("[Positive]", "").replace("[Negative]", "").replace("[Asked to leave]", "").trim();
            }

            options.push({
                html: '<option value="' + key + '">' + label + '</option>',
                label: label,
                key: key
            });
        });

        options = options.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        });

        $discharge_reasons.html(options.map(function (option) {
            return option.html;
        }).join(''));

        refresh_custom_discharge_reason($discharge_reasons.val());
    }
}

var $behaviour_queues = $(".js-behaviour-queue");
$(".js-change-queue-form").on('submit', function (event) {
    "use strict";

    var date_of_expected_discharge;
    var date_of_admission;
    var $visible_behaviour_queue = $behaviour_queues.filter(":visible");
    var $date_of_expected_discharge;
    var $date_of_admission;
    var $bedroom_id, bedroom_id;

    if ($visible_behaviour_queue.length > 0) {
        $date_of_expected_discharge = $visible_behaviour_queue.find('[name$="[date_of_expected_discharge]"]');
        $date_of_admission = $visible_behaviour_queue.find('[name$="[date_of_admission]"]');
        $bedroom_id = $visible_behaviour_queue.find('[name$="[bedroom_id]"]');

        if ($date_of_admission.length > 0) {
            date_of_admission = $date_of_admission.val().trim();

            if (date_of_admission == "") {
                alert("You must enter a date of admission before continuing.");
                event.preventDefault();
            }
        }

        if ($date_of_expected_discharge.length > 0) {
            date_of_expected_discharge = $date_of_expected_discharge.val().trim();

            if (date_of_expected_discharge == "") {
                alert("You must enter an expected discharge date before continuing.");
                event.preventDefault();
            }
        }

        $visible_behaviour_queue.each(function () {
            var $checkboxes = $(this).find('input').filter('[type="checkbox"]');
            var $override_checklist = $checkboxes.filter('[name*="override_checklist"]');

            $checkboxes = $checkboxes.not($override_checklist).not(".js-not-mandatory");

            if ($override_checklist.length === 0 || !$override_checklist.is(":checked")) {
                if ($checkboxes.length != $checkboxes.filter(':checked').length) {
                    console.log($checkboxes);
                    alert("You must check all the mandatory items before continuing.");
                    event.preventDefault();
                }
            }
        });
    }
});

$('.js-signature-modal').on('shown.bs.modal', function () {
    refresh_sigpads();
});

var old_funding_notes = $(".js-update-funding-notes").val();
$(".js-update-funding-notes").on("change keyup", function () {
    "use strict";

    var $el = $(this), $tr = $el.parents(".js-funding-notes-container"), handle_fail = function () {
        alert("An unexpected error occurred while updating the funding notes. Please refresh the page and try again.");
        $tr.addClass("danger");
        setTimeout(function () {
            $tr.removeClass("danger");
        }, 500);
    };

    if ($(this).val() !== old_funding_notes) {
        $.get(site_url("clients/set_funding_notes"), {
            "client_id": $el.data("client-id"),
            "funding": $el.val()
        }).done(function (data) {
            if (data == "OK") {
                $tr.addClass("success");
                setTimeout(function () {
                    $tr.removeClass("success");
                }, 500);
            } else {
                handle_fail();
            }
        }).fail(handle_fail);

        old_funding_notes = $el.val();
    }
});

var old_val = $(".js-treatment-contribution").val();
$(".js-treatment-contribution").on("change keyup", function () {
    "use strict";

    var $el = $(this), $tr = $el.parents("tr"), handle_fail = function () {
        alert("An unexpected error occurred while saving the weekly treatment contribution. Please refresh the page and try again.");
        $tr.addClass("danger");
        setTimeout(function () {
            $tr.removeClass("danger");
        }, 500);
    };

    if ($(this).val() !== old_val) {
        var contribution = parse_number($el.val());

        if (isNaN(contribution)) {
            contribution = 0;
        }

        $(".js-daily-contribution").html((contribution / 7).toFixed(2));
        $.get(site_url("clients/set_treatment_contribution"), {
            "client_id": $el.data("client-id"),
            "treatment_contribution": contribution
        }).done(function (data) {
            if (data == "OK") {
                $tr.addClass("success");
                setTimeout(function () {
                    $tr.removeClass("success");
                }, 500);
            } else {
                handle_fail();
            }
        }).fail(handle_fail);

        old_val = $el.val();
    }
});

function refresh_sigpads() {
    $(".sigPad").not("has-sigpad").each(function () {
        let verbal_consent = '[{"lx":294,"ly":91,"mx":294,"my":90},{"lx":294,"ly":90,"mx":294,"my":91},{"lx":293,"ly":91,"mx":294,"my":90},{"lx":292,"ly":92,"mx":293,"my":91},{"lx":291,"ly":93,"mx":292,"my":92},{"lx":290,"ly":94,"mx":291,"my":93},{"lx":287,"ly":92,"mx":287,"my":91},{"lx":288,"ly":91,"mx":287,"my":92},{"lx":289,"ly":91,"mx":288,"my":91},{"lx":289,"ly":92,"mx":289,"my":91},{"lx":290,"ly":92,"mx":289,"my":92},{"lx":290,"ly":93,"mx":290,"my":92},{"lx":291,"ly":93,"mx":290,"my":93},{"lx":291,"ly":94,"mx":291,"my":93},{"lx":292,"ly":94,"mx":291,"my":94},{"lx":293,"ly":95,"mx":292,"my":94},{"lx":288,"ly":95,"mx":288,"my":94}]';
        var $sigpad = $(this);
        var val = $(this).find("input").val();
        var mandatory = $sigpad.data("mandatory");
        var editable = $sigpad.data("editable");
        var signature_pad_object;
        var fullscreen = $sigpad.data("fullscreen") ? '<button type="button" class="btn btn-default fullscreen">Enter Full Screen</button>' : '';
        var verbalConsent = ($sigpad.data("verbal-consent") === undefined || $sigpad.data("verbal-consent")) ? '<button type="button" class="btn btn-default verbalConsent">Verbal Consent</button>' : '';

        if (typeof mandatory === "undefined") {
            mandatory = true;
        } else {
            mandatory = mandatory == true;
        }

        if (typeof editable === "undefined") {
            editable = true;
        }

        if (editable && $sigpad.find('.sigNav').length === 0) {
            $sigpad.prepend('<div class="sigNav btn-group btn-group-xs"><button type="button" class="btn btn-default clearButton">Clear Signature</button>' + verbalConsent + fullscreen + '</div>');
        }

        signature_pad_object = $sigpad.signaturePad({
            defaultAction: "drawIt",
            drawOnly: !$sigpad.data('do-not-require'),
            lineWidth: 0,
            displayOnly: !editable,
            validateFields: mandatory,
            onDraw() {
                $sigpad.find('.verbalConsent').addClass('btn-default').removeClass('btn-primary');
                $sigpad.removeClass('hasVerbalConsent');
            }
        });

        $(window).on('resize', function () {
            var $canvas = $sigpad.find('canvas');
            if ($sigpad.hasClass("sigFullscreen")) {
                $canvas.css('width', '100%').css('height', '100%');
                $canvas.prop('height', $canvas.height());
                $canvas.prop('width', $canvas.width());
                signature_pad_object.clearCanvas();
            }
        });

        $sigpad.on('click', '.clearButton', function () {
            $sigpad.find('.verbalConsent').addClass('btn-default').removeClass('btn-primary');
            $sigpad.removeClass('hasVerbalConsent');
        });

        $sigpad.on('click', '.fullscreen', function () {
            var $canvas = $sigpad.find('canvas');

            if (!$canvas.data('original-width')) {
                $canvas.data('original-height', $canvas.height());
                $canvas.data('original-width', $canvas.width());
            }

            if ($sigpad.hasClass("sigFullscreen")) {
                $(this).text('Enter Full Screen');
                $sigpad.removeClass("sigFullscreen");
                $canvas.prop('height', $canvas.data('original-height'));
                $canvas.prop('width', $canvas.data('original-width'));
                signature_pad_object.clearCanvas();
            } else {
                $(this).text('Exit Full Screen');
                $sigpad.addClass("sigFullscreen");
                $canvas.css('width', '100%').css('height', '100%');
                $canvas.prop('height', $canvas.height());
                $canvas.prop('width', $canvas.width());
                signature_pad_object.clearCanvas();
            }
        });

        $sigpad.on('click', '.verbalConsent', function () {
            if (!$sigpad.hasClass("hasVerbalConsent")) {
                $sigpad.find('.verbalConsent').removeClass('btn-default').addClass('btn-primary');
                $sigpad.addClass('hasVerbalConsent');
            }

            signature_pad_object.regenerate(verbal_consent);
        });

        $sigpad.data("sigpad-object", signature_pad_object);
        $sigpad.addClass("has-sigpad");

        if (val) {
            signature_pad_object.regenerate(val);

            if (val === verbal_consent) {
                $sigpad.addClass('hasVerbalConsent');
                $sigpad.find('.verbalConsent').removeClass('btn-default').addClass('btn-primary');
            }
        }
    });
}

refresh_sigpads();

var $tab_buttons = $('a[data-toggle="tab"]');

if ($tab_buttons.length > 0) {
    $tab_buttons.on('shown.bs.tab', function (e) {
        var $a = $(e.target);
        var id = $a.attr("href");
        var $tab = $(id);
        var $wellbeing_chart = $('#wellbeing-chart:visible');

        if (typeof (client_id) !== "undefined") {
            History.pushState({tab: id}, $a.text() + " · " + $('title').text().split("·")[1], site_url("clients/view/" + client_id + '/' + id.substr(1)));
        } else if (typeof (user_id) !== "undefined") {
            History.pushState({tab: id}, $a.text() + " · " + $('title').text().split("·")[1], site_url("users/view/" + user_id + '/' + id.substr(1)));
        }

        autosize.update($("textarea"));
        $('.fc-today-button').click();
        $(".js-masonry").masonry();

        refresh_sigpads();

        if ($wellbeing_chart.length > 0) {
            update_wellbeing_chart();
        }

        // Scroll back up.
        $('html, body').animate({
            scrollTop: $tab.offset().top - 39
        }, 500);
    });

    tab = $tab_buttons.parents().filter(".active").find("a").attr('href');
    $(".tab-pane").filter(tab).removeClass("fade").addClass("in active").addClass("fade");
}

$(".js-bedroom-masonry").masonry();

$(".js-add-care-plan-row").on("click", function (event) {
    "use strict";

    var firstCarePlan = $(".js-care-plan-row").first().clone();
    firstCarePlan.find("input, textarea, select").val("");
    $(".js-care-plan-row-container").append(firstCarePlan);
    event.preventDefault();
});

$(".js-case-note-type-select").on("change", function (event) {
    "use strict";
    var val = parseInt($(this).val());
    var client_signature = '<div class="col-sm-6 js-client-case-note-signature"><h5>' + __client_signature + '</h5><div class="sigPad"><div class="sig sigWrapper"><canvas class="pad" width="300" height="100"></canvas><input type="hidden" name="client_signature" class="output"></div></div></div>';
    var worker_signature = '<div class="col-sm-6 js-worker-case-note-signature"><h5>' + __worker_signature + '</h5><div class="sigPad"><div class="sig sigWrapper"><canvas class="pad" width="300" height="100"></canvas><input type="hidden" name="worker_signature" class="output"></div></div></div>';

    if (val > 0) {
        case_note_types[val].require_client_signature = parseInt(case_note_types[val].require_client_signature);
        case_note_types[val].require_worker_signature = parseInt(case_note_types[val].require_worker_signature);

        if (case_note_types[val].require_client_signature) {
            $(".js-client-case-note-signature-container").html(client_signature);
            refresh_sigpads();
        } else {
            $(".js-client-case-note-signature-container").html("");
        }

        if (case_note_types[val].require_worker_signature) {
            $(".js-worker-case-note-signature-container").html(worker_signature);
            refresh_sigpads();
        } else {
            $(".js-worker-case-note-signature-container").html("");
        }
    } else {
        $(".js-client-case-note-signature-container").html("");
        $(".js-worker-case-note-signature-container").html("");
    }
}).change();

var $audit_log_rows = $(".js-audit-log-row");
$(".js-audit-log-user-id-picker").on("change", function () {
    "use strict";

    var value = $(this).val();
    $audit_log_rows.show();
    if (value != '') {
        $audit_log_rows.not(".js-audit-log-" + value).hide();
    }
});


$(".js-client-container").sortable({
    connectWith: ".js-client-container",
    receive: function (event, ui) {
        "use strict";

        var person_gender = $(ui.item).data('gender'), room_gender = $(this).data('gender');
        var $sender = $(ui.sender);
        var $old_input;
        var old_notes;

        // Adds handling for the new sex codes (ClientStatedSex).
        if (person_gender === 1) {
            person_gender = "M";
        } else if (person_gender === 2) {
            person_gender = "F";
        }

        if (!$(this).is(".js-not-real-bed")) {
            if (room_gender != "X" && person_gender != room_gender) {
                // Cancel because this room doesn't allow people of this gender.
                $sender.sortable('cancel');
                return;
            } else if ($(this).children().length > 1) {
                $sender.append($(this).children().not("#" + $(ui.item).attr('id')));
            }
            //
            // $old_input = $sender.parents(".panel-body").find(".js-bed-notes");
            // old_notes = $old_input.val();
            // $old_input.val("");
            // set_bed_notes($old_input.data("bedroom-id"), $old_input.data("bed-number"), $old_input.val());
            //
            // $(event.target).parents(".panel-body").find(".js-bed-notes").val(old_notes).trigger("input");
        }

        $(".js-bedroom-masonry").masonry();
        store_bedroom_data($(this));
    }
}).disableSelection();

$(".js-client-record").find("input").on("dp.change", store_bedroom_data).on("keyup", store_bedroom_data);

$(".js-select-medication").on("change", function () {
    "use strict";

    var $el = $(this),
        $parent = $el.parents("form"),
        val = $el.val();

    if (val !== "custom") {
        val = parseInt(val);
        if (typeof (medications[val]) !== "undefined") {
            $parent.find("#measure").val(medications[val].measure);
            $parent.find("#route_of_administration_code").val(medications[val].route_of_administration_code);
            $parent.find("#breakfast").val(medications[val].breakfast);
            $parent.find("#lunch").val(medications[val].lunch);
            $parent.find("#dinner").val(medications[val].dinner);
            $parent.find("#evening").val(medications[val].evening);
        }
    }
});

function store_bedroom_data(ui) {
    "use strict";


    var clients = {};
    var data = $(".js-drag-and-drop-form").serializeArray();

    $(".js-client-record").each(function () {
        var client_id = $(this).data('client-id');
        var $bedroom = $(this).parents(".js-client-container");
        if ($bedroom.is(".js-not-real-bed")) {
            // Client has no bed.
            clients[client_id] = {
                bedroom_id: null,
                bedroom_order: null
            };
        } else {
            clients[client_id] = {
                bedroom_id: $bedroom.data('bedroom-id'),
                bedroom_order: $bedroom.data('bed-number')
            };
        }
    });

    data.push({name: "clients", value: JSON.stringify(clients)});
    $.post(site_url("bedrooms/store_drag_and_drop"), data, 'json').then(function (data) {
        var success_class = "has-success";
        var error_class = "has-error";

        if (ui && !ui.is(".form-group")) {
            success_class = "bg-success";
            error_class = "bg-danger";
        }
        if (ui) {
            ui.removeClass(error_class);
            ui.addClass(success_class);

            setTimeout(function () {
                ui.removeClass(success_class);
            }, 1000);
        } else {
            ui.removeClass(success_class);
            ui.addClass(error_class);
        }

    }).fail(function () {

    });

}

$("#js-care-plan-form").on("submit", function () {
    "use strict";

    var $form = $(this),
        $rows = $form.find('.js-care-plan-row'),
        data = [],
        row = {};

    // If there are any JSON fields, convert the form into JSON format and submit.
    if ($form.find('[name*="json"]').length > 0) {
        $rows.each(function () {
            $(this).find('[name*="json"]').each(function () {
                row[$(this).attr("name").replace("json[", "").replace("]", "")] = $(this).val();
            });
            data.push(row);
        });
        $("#care-plan-input").val(JSON.stringify(data));
    }
});

$(document).on("change", "#client-select", function () {
    var $el = $(this);
    var $tr = $el.parents("tr");
    var payment_id = $el.data('payment-id');
    var val = $el.val();

    $.getJSON(site_url("client_payments/set_client/" + payment_id + "/" + val)).then(function (data) {
        if (typeof data.success === "undefined" || !data.success) {
            alert("An error occurred while trying to save data for this payment. Please refresh and try again.");
            $tr.removeClass("success");
            $tr.addClass("error");
        } else {
            $tr.removeClass("error");
            $tr.addClass("success");

            setTimeout(function () {
                $tr.removeClass("success");
            }, 500);
        }
    }).fail(function () {
        $tr.removeClass("success");
        $tr.addClass("error");
        alert("An error occurred while trying to save data for this payment. Please refresh and try again.");
    });
});

$(document).on("input change", "#custom_booking_deposit, #balance_to_collect", function () {
    var booking_deposit = parseFloat($("#custom_booking_deposit").val());
    var balance_to_collect = parseFloat($("#balance_to_collect").val());
    if (isNaN(booking_deposit)) {
        booking_deposit = 0;
    }
    if (isNaN(balance_to_collect)) {
        balance_to_collect = 0;
    }
    var total = booking_deposit + balance_to_collect;
    $("#total_treatment_cost").val(total.toFixed(2));
});

function set_bed_notes(bedroom_id, bed_number, notes, $el_to_set_success) {
    var success_class = "has-success";
    var error_class = "has-error";
    var data = {
        bedroom_id: bedroom_id,
        bed_number: bed_number,
        notes: notes
    };

    if ($el_to_set_success && !$el_to_set_success.is(".form-group")) {
        success_class = "bg-success";
        error_class = "bg-danger";
    }

    $.post(site_url("bedrooms/set_notes"), data, 'json').then(function (data) {
        if (typeof data.success === "undefined" || !data.success) {
            alert("An error occurred while trying to set the notes for this bed. Please refresh and try again.");
            if ($el_to_set_success) {
                $el_to_set_success.removeClass(success_class);
                $el_to_set_success.addClass(error_class);
            }
        } else {
            if ($el_to_set_success) {
                $el_to_set_success.removeClass(error_class);
                $el_to_set_success.addClass(success_class);

                setTimeout(function () {
                    $el_to_set_success.removeClass(success_class);
                }, 1000);
            }
        }
    }).fail(function () {
        if ($el_to_set_success) {
            $el_to_set_success.removeClass(success_class);
            $el_to_set_success.addClass(error_class);
        }
        alert("An error occurred while trying to set the notes for this bed. Please refresh and try again.");
    });
}

function set_custom_field(client_id, field, value, $el_to_set_success, is_episode_field) {
    var success_class = "has-success";
    var error_class = "has-error";
    var data = {
        client_id: client_id
    };
    data[field] = value;

    data['is_episode_field'] = !!is_episode_field;

    if (!$el_to_set_success.is(".form-group")) {
        success_class = "bg-success";
        error_class = "bg-danger";
    }

    $.post(site_url("clients/set_custom_field"), data, 'json').then(function (data) {
        if (typeof data.success === "undefined" || !data.success) {
            alert("An error occurred while trying to save data for this client. Please refresh and try again.");
            $el_to_set_success.removeClass(success_class);
            $el_to_set_success.addClass(error_class);
        } else {
            $el_to_set_success.removeClass(error_class);
            $el_to_set_success.addClass(success_class);

            setTimeout(function () {
                $el_to_set_success.removeClass(success_class);
            }, 1000);
        }
    }).fail(function () {
        $el_to_set_success.removeClass(success_class);
        $el_to_set_success.addClass(error_class);
        alert("An error occurred while trying to save data for this client. Please refresh and try again.");
    });
}

$(document).on("input change dp.change", ".js-custom-field", _.debounce(function () {
    var $el = $(this);
    set_custom_field($el.data("client-id"), $el.prop("name"), $el.val(), $el.parents(".form-group"));
}, 500));

$(document).on("input change dp.change", ".js-custom-episode-field", _.debounce(function () {
    var $el = $(this);
    set_custom_field($el.data("client-id"), $el.prop("name"), $el.val(), $el.parents(".form-group"), true);
}, 500));

if (typeof (client_id) !== "undefined" || typeof (user_id) !== "undefined") {
    if ($(".tab-pane").length > 0) {
        History.Adapter.bind(window, 'statechange', function () {
            var state = History.getState();
            var page = (typeof (client_id) === "undefined") ? "users" : "clients";
            var parts = state.url.replace(site_url(page + "/view/"), "").split('/');
            var tab = (typeof parts[1] === "undefined" || parts[1] == "") ? "index" : parts[1];

            if (!$("#" + tab).is(":visible")) {
                $('a[data-toggle="tab"][href="#' + tab + '"]').click();
            }
        });

        History.Adapter.bind(window, 'hashchange', function (event) {
            var tab = event.target.location.hash.substr(1);

            if (!$("#" + tab).is(":visible")) {
                $('a[data-toggle="tab"][href="#' + tab + '"]').click();
            }
        });

        $(window).trigger('statechange');
    }
}

var holder = $('#wellbeing-chart');

$(document).on("click", ".js-auto-fill", function () {
    let days = 0;
    $("textarea, input[type=text]:not(.has-date-picker, .date-picker)").each(function () {
        $(this).val($(this).attr("name"));
    });
    $("select").each(function () {
        let index = Math.floor(Math.random() * $(this).find("option").length) + 1;
        index = index === 1 ? 2 : index;
        $(this).find("option:nth-child(" + index + ")").prop("selected", true);
    });
    $(".radio-btn-group").each(function () {
        var $el = $(this).find('[value="Yes"], [value="1"], [value="Y"]');
        if ($el.length === 0) {
            $el = $(this).find('[value]:first');
        }

        $el.parent().click();
    });
    $('input[type=checkbox]').click();
    $(".has-date-picker, .date-picker").each(function () {
        const date = moment().startOf('month').add(days, 'day').format('DD/MM/YYYY');
        $(this).val(date).change();
        days++;
    });
});

if (holder.length) {

    chartOptions = {
        xaxis: {
            min: earliest_tick,
            max: latest_tick,
            mode: "time",
            minTickSize: [3, "day"],
            tickLength: 0,
            timeformat: "%d/%m/%y"
        },
        yaxis: {},
        series: {
            lines: {
                show: true,
                fill: true,
                lineWidth: 3
            },
            points: {
                show: true,
                radius: 3,
                fill: true,
                fillColor: "#ffffff",
                lineWidth: 2
            }
        },
        grid: {
            hoverable: true,
            clickable: false,
            borderWidth: 0
        },
        legend: {
            show: true
        },
        tooltip: true,
        tooltipOpts: {
            content: '%s [%x]: %y'
        },
        colors: mvpready_core.layoutColors
    };

    $('#trend_category').on('change', update_wellbeing_chart).change();
}

(function () {
    "use strict";

    var Cache = {
        common: {},
        medications: {},
        sessions: {
            $client_names: null,
            $client_ids: null,
            $max_number_of_attendees: null,
            count_of_my_clients: null
        }
    };

    var Helpers = {
        sessions: {
            create_and_edit: function () {
                $('#max_number_of_attendees').on('keyup change', function () {
                    var val = parseInt($(this).val());

                    if (isNaN(val) || val <= 0) {
                        return;
                    }

                    var $trs = $('.client-beds tr');
                    var length = $trs.length;

                    if (length > val) {
                        $trs.slice(val).remove();
                    } else if (length < val) {
                        $('.use-typeahead').removeClass('has-typeahead').typeahead('destroy');
                        var copy = "<tr>" + $trs.first().html().replace('has-typeahead', '') + "</tr>";
                        var copies = val - length;
                        var i = length + 1;
                        var new_html = '';
                        while (copies > 0) {
                            new_html += copy.replace(/#\d/, '#' + i).replace('names__', 'names_' + i + '_');
                            copies--;
                            i++;
                        }
                        new_html = $(new_html);
                        new_html.find("input").val("");
                        $('.client-beds').append(new_html);
                        initialise_typeahead('.use-typeahead');
                    }

                    // Refresh the cache.
                    Project.sessions.init();
                });

                $(".js-auto-fill-my-clients").on("click", function auto_fill_my_clients() {
                    "use strict";

                    var i = 0;

                    Cache.sessions.$max_number_of_attendees.val(Cache.sessions.count_of_my_clients).change();
                    $.each(my_clients, function (id, name) {
                        console.log(i, Cache.sessions.$client_names);
                        $(Cache.sessions.$client_names[i]).typeahead('val', name);
                        $(Cache.sessions.$client_ids[i]).val(id);
                        i++;
                    });
                });
            },
        },
        forms: {
            repopulate_form_from_answers: function () {
                var fill_answer = function (selector, value) {
                    var $field = $(selector).not(".js-do-not-repopulate");

                    var type = $field.attr("type");

                    if (!type) {
                        if ($field.is("textarea")) {
                            type = "textarea";
                        } else if ($field.is("select")) {
                            type = "select";
                        } else if ($field.is("input")) {
                            type = "text";
                        }
                    }

                    switch (type) {
                        case "text":
                        case "password":
                        case "email":
                        case "number":
                        case "select":
                            $field.val(value).change();
                        case "textarea":
                            $field.val(value).change();
                            autosize.update($field);
                            break;
                        case "checkbox":
                            if (value) {
                                if (!$field.is(":checked")) {
                                    $field.click().change();
                                }
                            } else {
                                if ($field.is(":checked")) {
                                    $field.click().change();
                                }
                            }
                            break;
                        case "radio":
                            if (value) {
                                $field.filter('[value="' + value + '"]').click().change();
                            }
                            break;
                    }
                };

                if (typeof (fill_form_answers) !== "undefined") {
                    $.each(fill_form_answers, function (key, value) {
                        if (value === null) {
                            // Nothing to do here.
                            return;
                        } else if (Array.isArray(value) || typeof value === 'object') {
                            $.each(value, function (subkey, subvalue) {
                                fill_answer('[name="json[' + key + '][' + subkey + ']"], [name="client[' + key + '][' + subkey + ']"], [name="episode[' + key + '][' + subkey + ']"]', subvalue);
                            });
                        } else {
                            fill_answer('[name="json[' + key + ']"], [name="client[' + key + ']"], [name="episode[' + key + ']"]', value);
                        }
                    });
                }
            }
        }
    };

    var Project = {
        common: {
            init: function () {

            },
            finalize: function () {

            }
        },
        bedrooms: {
            drag_and_drop: function () {
                $("body").on("input", ".js-bed-notes", _.debounce(function () {
                    var $el = $(this);
                    var $parent = $el.parents(".panel-body");
                    set_bed_notes($el.data("bedroom-id"), $el.data("bed-number"), $el.val(), $parent);
                }, 500));
            }
        },
        client_payments: {
            init: function () {
                $(".js-client-for-payments-selector, .js-slug-selector").on("change", function () {
                    $.each(client_data_for_payments[$('.js-client-for-payments-selector').val()], function (key, value) {
                        var $input = $('[name="' + key + '"]');

                        if ($input.length > 0) {
                            $input.val(value);
                        } else {
                            if ($('[name="slug"]').val() == key) {
                                $('[name="amount"]').val(value);
                            }
                        }
                    });
                });
            }
        },
        clients: {
            init: function () {
                $(".js-modalities").hide();
                $(".js-modality-select").on("change", function () {
                    const vals = $(this).val().map((val) => parseInt(modalities_breakdown[val]));
                    if (vals.length === 0) {
                        console.log("Hiding modalities");
                        $(".js-modalities").hide();
                    } else {
                        console.log("Showing modalities");
                        $(".js-modalities").show();
                    }

                    const interventionMap = {
                        '94': 'PharmacologicalIntervention',
                        '95': 'PsychosocialIntervention',
                        '96': 'RecoverySupport',
                    };

                    for (const key in interventionMap) {
                        console.log('what key', key, vals, vals.includes(parseInt(key)));
                        if (vals.includes(parseInt(key))) {
                            console.log(`.js-modality-${interventionMap[key]}`);
                            $(`.js-modality-${interventionMap[key]}`).show();
                        } else {
                            console.log(`NOT .js-modality-${interventionMap[key]}`);
                            $(`.js-modality-${interventionMap[key]}`).hide();
                        }
                    }
                }).change();
            },
            edit_form: function () {
                Helpers.forms.repopulate_form_from_answers();
            },
            view: function () {
                var reflect_change;
                var stop_editing = function ($el, save) {
                    var is_save = (typeof save === "undefined") ? true : (!!save);
                    var $input = $el.find(".js-input");
                    var val = $input.val();
                    var val_label;
                    var $text = $el.find(".list-group-item-text");

                    if (is_save) {
                        if ($input.is("select")) {
                            val_label = $input.find(":selected").html();
                        } else {
                            val_label = val;
                        }
                        set_custom_field($el.data("client-id"), $el.data("dashboard-edit"), val, $el);
                        $el.data("template-default-val", val);
                        $text.html(val_label);
                    }

                    $el.removeClass("is-editing");
                    $el.find(".js-edit-container").remove();
                    $text.show();
                    $(".js-masonry").masonry();
                };

                $("body").on("click", function (event) {
                    var $target = $(event.target);
                    var $parent = $target.parents("[data-dashboard-edit]");

                    if ($parent.length > 0) {
                        $target = $parent;
                    }

                    $(".is-editing").each(function () {
                        var $el = $(this);
                        if (!$el.is($target)) {
                            stop_editing($el);
                        }
                    });
                });

                $("#index").on("click", "[data-dashboard-edit]", function () {
                    var $el = $(this);
                    var is_editing = $el.hasClass("is-editing");
                    var $text = $el.find(".list-group-item-text");
                    var $input;
                    var type = $el.data("type") ? $el.data("type") : null;
                    var input_html;
                    var options = '';
                    var val = $text.html();
                    var $js_edit;

                    if (type === null) {
                        $js_edit = $el.find(".js-server-side-rendered");
                        if ($js_edit.length > 0) {
                            type = "server-side";
                            $el.data("type", type);
                            $el.data("template-default-val", $js_edit.find('.js-input').val());
                            $el.data("template", $js_edit.html());
                            $js_edit.remove();
                        } else {
                            type = "text";
                        }
                    }

                    if (!is_editing) {
                        $el.addClass("is-editing");

                        switch (type) {
                            case "date":
                                input_html = '<input class="js-input date-picker form-control" type="text">';
                                break;
                            case "date-with-weeks":
                                val = val.split(" ")[0];
                                options += '<option value="custom">Custom...</option>';
                                options += '<option value="10 days">10 days</option>';
                                for (var i = 1; i <= 38; i++) {
                                    options += '<option value="' + i + ' weeks">' + i + ' weeks</option>';
                                }

                                input_html = "";
                                input_html += '<select class="js-week-selector form-control">' + options + '</select><br />';
                                input_html += '<input class="js-input date-picker form-control" type="text">';
                                break;
                            case "server-side":
                                input_html = $el.data('template');
                                val = $el.data("template-default-val");
                                break;
                            default:
                                input_html = '<input class="js-input form-control" type="text">';
                                break;
                        }

                        $input = $('<div class="js-edit-container">' + input_html + '<br /><div class="btn-group"><button type="submit" class="btn btn-sm js-save btn-success">Save</button><button type="submit" class="btn btn-sm js-cancel btn-default">Cancel</button></div></div>');
                        $input.find(".js-input").val(val);
                        $text.hide();
                        $el.append($input);
                        $(".js-masonry").masonry();

                        if (typeof ($.fn.datetimepicker) !== "undefined" && typeof (init_datepickers) !== "undefined") {
                            init_datepickers();
                        }
                    }
                }).on("click", ".is-editing .js-save", function (event) {
                    var $el = $(this).parents(".is-editing");
                    stop_editing($el);
                    event.stopPropagation();
                }).on("click", ".is-editing .js-cancel", function (event) {
                    var $el = $(this).parents(".is-editing");
                    stop_editing($el, false);
                    event.stopPropagation();
                });

                $("body").on("input change", ".js-booking-deposit-field", function () {
                    var $el = $(this);
                    var client_id = $el.data("client-id");
                    var $form_group = $el.parents(".form-group");
                    var url = site_url("clients/update_booking_deposit/" + client_id);
                    var data = {
                        booking_deposit: $form_group.find('[name="booking_deposit"]').val(),
                        booking_deposit_payment_collected: $form_group.find('[name="booking_deposit_payment_collected"]:checked').val()
                    };
                    $.post(url, data, "json").then(function (data) {
                        if (typeof data.success === "undefined" || !data.success) {
                            alert("An error occurred while trying to save this client's booking deposit details. Please refresh and try again.");
                            $form_group.removeClass("has-success");
                            $form_group.addClass("has-error");
                        } else {
                            $form_group.removeClass("has-error");
                            $form_group.addClass("has-success");

                            setTimeout(function () {
                                $form_group.removeClass("has-success");
                            }, 1000);
                        }
                    }).fail(function () {
                        $form_group.removeClass("has-success");
                        $form_group.addClass("has-error");
                        alert("An error occurred while trying to save this client's booking deposit details. Please refresh and try again.");
                    });
                });

                $(".checklist-item-label").not(".js-behaviour-queue .checklist-item-label")
                    .on("change", '[type="checkbox"]', function () {
                        var $checkbox = $(this);
                        var $container = $checkbox.parents(".checklist-item-label");

                        var notes = $container.find('[type="text"]').val() + '';

                        $.get(site_url("clients/store_checklist_item"), {
                            "client_id": $container.data("client-id"),
                            "item_id": $container.data("item-id"),
                            "is_checked": $checkbox.is(":checked"),
                            "notes": notes
                        }).done(function () {
                            $container.addClass("success");
                            setTimeout(function () {
                                $container.removeClass("success");
                            }, 500);
                        }).fail(function () {
                            alert("An unknown error occurred while trying to update this checklist item.");
                        });
                    })
                    .on("keyup change", '[type="text"]', function (event) {
                        var $container = $(this).parents(".checklist-item-label");
                        var old_value = $container.data('old-value') + '';
                        var new_value = $(this).val();
                        $container.data('old-value', new_value);

                        if (old_value != new_value || event.type == "change") {
                            clearTimeout(reflect_change);
                            reflect_change = setTimeout(function () {
                                var notes = $container.find('[type="text"]').val();
                                notes = notes ? notes : "";

                                $.get(site_url("clients/store_checklist_item"), {
                                    "client_id": $container.data("client-id"),
                                    "item_id": $container.data("item-id"),
                                    "notes": notes
                                }).done(function () {
                                    $container.addClass("success");
                                    setTimeout(function () {
                                        $container.removeClass("success");
                                    }, 500);
                                }).fail(function () {
                                    alert("An unknown error occurred while trying to update this checklist item.");
                                });
                            }, event.type == "change" ? 0 : 1500);
                        }
                    });
            }
        },
        medications: {
            init: function () {

            },
            list_administer: function () {
                refresh_sigpads();
            }
        },
        pages: {
            init: function () {
                Helpers.forms.repopulate_form_from_answers();
            },
            help: function () {
                var $rows = $('.videoList');
                $('.searchVideos').keyup(function () {
                    var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase().split(' ');

                    $rows.hide().filter(function () {
                        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
                        var matchesSearch = true;
                        $(val).each(function (index, value) {
                            matchesSearch = (!matchesSearch) ? false : ~text.indexOf(value);
                        });
                        return matchesSearch;
                    }).show();
                });
            },
            index: function () {
                $(".js-notes").on("change keyup", function () {
                    if ($(this).val() == "") {
                        $(this).data("edited", false);
                    } else {
                        $(this).data("edited", true);
                    }
                });

                $(".js-all-notes").on("change keyup", function () {
                    var new_val = $(this).val();
                    $(this).parents("form").find(".js-notes").each(function () {
                        if (!$(this).data("edited")) {
                            $(this).val(new_val);
                        }
                    });
                });
            }
        },
        sessions: {
            init: function () {
                Cache.sessions.$client_names = $(".js-client-names").filter("[name]");
                Cache.sessions.$client_ids = $(".js-client-ids");
                Cache.sessions.$max_number_of_attendees = $("#max_number_of_attendees");
                if (typeof (my_clients) !== "undefined") {
                    Cache.sessions.count_of_my_clients = Object.keys(my_clients).length;
                }
            },

            create: Helpers.sessions.create_and_edit,
            edit: Helpers.sessions.create_and_edit
        },
        users: {
            init: function () {
                $(".js-add-document").on("click", function (event) {
                    var $documents = $(".js-documents");
                    var count_documents = $documents.find(".row").length;

                    event.preventDefault();

                    // We don't increment count_documents by one because it's 1-indexed, not 0-indexed, so the count is always key+1 already.
                    $(".js-documents").append($(".js-sample-document").html().split("[]").join("[" + count_documents + "]"));
                });

                $(".js-remove-document").on("click", function (event) {
                    event.preventDefault();
                    $(this).parents(".js-existing-document").slideUp(function () {
                        $(this).remove();
                    });
                });
            },
            view: function () {
                var $user_note_rows = $(".js-user-note-row"),
                    $trainings_chart = $('#trainings-chart'),
                    mouse_x = 0,
                    mouse_y = 0;
                $(".js-user-note-type-id-picker").on("change", function () {
                    "use strict";

                    var value = $(this).val();
                    $user_note_rows.show();
                    if (value > 0) {
                        $user_note_rows.not(".js-user-note-type-" + value).hide();
                    }
                });

                $("#user_note_type_id").on('change input', function () {
                    const value = parseInt($(this).val());
                    if (types_with_signature.indexOf(value) !== -1) {
                        $(".js-trigger-sigpad").slideDown();
                    } else {
                        $(".js-trigger-sigpad").slideUp();
                    }
                });

                $(".js-trigger-sigpad").not("form").hide();

                if ($trainings_chart.length > 0) {
                    $("body").mousemove(function (e) {
                        mouse_x = e.pageX;
                        mouse_y = e.pageY;
                    })

                    $.plot($trainings_chart, trainings_data, {
                        series: {
                            pie: {
                                show: true,
                                innerRadius: .5,
                                stroke: {
                                    width: 4
                                }
                            }
                        },
                        legend: {
                            position: 'ne'
                        },
                        tooltip: true,
                        tooltipOpts: {
                            content: '%s',
                            onHover: function (flotItem, $tooltipEl) {
                                $tooltipEl.css({
                                    left: mouse_x + 20,
                                    top: mouse_y + 10
                                });
                            },
                        },
                        grid: {
                            hoverable: true
                        },
                    });
                }
            }
        }
    };

    window.PROJECT = Project;
})();


UTIL = {
    fire: function (func, funcname, args) {
        var namespace = PROJECT;
        funcname = (funcname === undefined) ? 'init' : funcname;
        if (func !== '' && namespace[func] && typeof namespace[func][funcname] == 'function') {
            namespace[func][funcname](args);
        }
    },
    loadEvents: function () {
        var bodyId = document.body.id;
        UTIL.fire('common');
        $.each(document.body.className.split(/\s+/), function (i, classnm) {
            UTIL.fire(classnm);
            UTIL.fire(classnm, bodyId);
        });
        UTIL.fire('common', 'finalize');
    }
};

UTIL.loadEvents();

(function ($, window, document) {
    var is_submitting = true;
    var base = $("#wrapper").children(".content");
    var previous = "";

    $(document).on('submit', 'form', function () {
        is_submitting = true;
    });

    window.onbeforeunload = function () {
        if (!is_submitting && !ENV_IS_DEBUGGING) {
            return "You are about to leave this page. If you do so, all the data you have entered so far will be lost. Are you sure that you wish to leave this page?";
        }
    };

    base.find("select, input, textarea").on('focus', function () {
        var $el = $(this);
        var type = $el.attr("type");

        if (type == "checkbox" || type == "radio") {
            previous = $el.is(":checked");
        } else {
            previous = $el.val();
        }
    }).on("change keyup", function () {
        var $el = $(this);
        var type = $el.attr("type");
        var value;

        if (type == "checkbox" || type == "radio") {
            value = $el.is(":checked");
        } else {
            value = $el.val();
        }

        if (value !== previous) {
            is_submitting = false;
        }
    });

})(jQuery, window, document);